import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../components/seo';
import LayoutContent from '../components/layout/layout-content';
import Container from '../components/container/container';
import { PageHeading } from '../components/page-heading/page-heading';
import styles from '../styles/referenzen.module.scss';
import { TestimonialLink } from '../components/testimonial-link/testimonial-link';
import TrailAnimation from '../components/animate/TrailAnimation';

const TestimonialOverviewPage = () => {
  const { allSanityTestimonial } = useStaticQuery(graphql`
    {
      allSanityTestimonial(sort: { fields: completedAt, order: DESC }) {
        nodes {
          _id
          description: _rawDescription
          personName
          website
          slug {
            current
          }
          mainImage {
            asset {
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 500
                    traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#ffffff", color: "#828282" }
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const { nodes } = allSanityTestimonial;

  return (
    <LayoutContent>
      <SEO title="Referenzen - Evelan GmbH" />
      <div className={styles.background} />
      <Container>
        <PageHeading>Referenzen</PageHeading>
      </Container>
      <Container>
        <TrailAnimation
          variant="SlideUpHeavy"
          delayBetweenItems={100}
          delayBeforeStart={500}
          component={<div className={styles.testimonialContainer} />}
        >
          {nodes.map((node, index) => {
            return <TestimonialLink testimonial={node} key={`testimonial-${index}`} />;
          })}
        </TrailAnimation>
      </Container>
    </LayoutContent>
  );
};

export default TestimonialOverviewPage;
