import React from 'react';
import TrailAnimation from '../animate/TrailAnimation';
import styles from './page-heading.module.scss';

export const PageHeading = ({ children }) => {
  return (
    <TrailAnimation variant="SlideUpClipPerWord" delayBeforeStart={100} component={<h1 className={styles.underline} />}>
      {children}
    </TrailAnimation>
  );
};
